export const Metadata = () => import('../../components/metadata.vue' /* webpackChunkName: "components/metadata" */).then(c => wrapFunctional(c.default || c))
export const NotFound = () => import('../../components/not-found.vue' /* webpackChunkName: "components/not-found" */).then(c => wrapFunctional(c.default || c))
export const Schemas = () => import('../../components/schemas.vue' /* webpackChunkName: "components/schemas" */).then(c => wrapFunctional(c.default || c))
export const SinglePostListing = () => import('../../components/single-post-listing.vue' /* webpackChunkName: "components/single-post-listing" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooter = () => import('../../components/layouts/footer.vue' /* webpackChunkName: "components/layouts-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeader = () => import('../../components/layouts/header.vue' /* webpackChunkName: "components/layouts-header" */).then(c => wrapFunctional(c.default || c))
export const TwButton = () => import('../../components/tw/button.vue' /* webpackChunkName: "components/tw-button" */).then(c => wrapFunctional(c.default || c))
export const TwImage = () => import('../../components/tw/image.vue' /* webpackChunkName: "components/tw-image" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
