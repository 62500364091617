import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dd54bcb4 = () => interopDefault(import('../pages/root-domain/index.vue' /* webpackChunkName: "pages/root-domain/index" */))
const _c18aabe0 = () => interopDefault(import('../pages/sub-domain/index.vue' /* webpackChunkName: "pages/sub-domain/index" */))
const _0ed31a81 = () => interopDefault(import('../pages/root-domain/blog/index.vue' /* webpackChunkName: "pages/root-domain/blog/index" */))
const _43b4c457 = () => interopDefault(import('../pages/sub-domain/blog/index.vue' /* webpackChunkName: "pages/sub-domain/blog/index" */))
const _081aa8a8 = () => interopDefault(import('../pages/root-domain/test/screenshots.vue' /* webpackChunkName: "pages/root-domain/test/screenshots" */))
const _7f2983f5 = () => interopDefault(import('../pages/root-domain/blog/category/:slug.vue' /* webpackChunkName: "pages/root-domain/blog/category/:slug" */))
const _08225342 = () => interopDefault(import('../pages/root-domain/blog/tag/:slug.vue' /* webpackChunkName: "pages/root-domain/blog/tag/:slug" */))
const _4fede838 = () => interopDefault(import('../pages/sub-domain/blog/_.vue' /* webpackChunkName: "pages/sub-domain/blog/_" */))
const _294d070e = () => interopDefault(import('../pages/root-domain/blog/_.vue' /* webpackChunkName: "pages/root-domain/blog/_" */))
const _5050b61d = () => interopDefault(import('../pages/sub-domain/_.vue' /* webpackChunkName: "pages/sub-domain/_" */))
const _6a92e8b3 = () => interopDefault(import('../pages/root-domain/_.vue' /* webpackChunkName: "pages/root-domain/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/root-domain",
    component: _dd54bcb4,
    name: "root-domain"
  }, {
    path: "/sub-domain",
    component: _c18aabe0,
    name: "sub-domain"
  }, {
    path: "/root-domain/blog",
    component: _0ed31a81,
    name: "root-domain-blog"
  }, {
    path: "/sub-domain/blog",
    component: _43b4c457,
    name: "sub-domain-blog"
  }, {
    path: "/root-domain/test/screenshots",
    component: _081aa8a8,
    name: "root-domain-test-screenshots"
  }, {
    path: "/root-domain/blog/category/:slug",
    component: _7f2983f5,
    name: "root-domain-blog-category-:slug"
  }, {
    path: "/root-domain/blog/tag/:slug",
    component: _08225342,
    name: "root-domain-blog-tag-:slug"
  }, {
    path: "/sub-domain/blog/*",
    component: _4fede838,
    name: "sub-domain-blog-all"
  }, {
    path: "/root-domain/blog/*",
    component: _294d070e,
    name: "root-domain-blog-all"
  }, {
    path: "/sub-domain/*",
    component: _5050b61d,
    name: "sub-domain-all"
  }, {
    path: "/root-domain/*",
    component: _6a92e8b3,
    name: "root-domain-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
