export default () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "79dbc762-0510-44cc-a89a-e54ef6293be1";

    (function () {
        var d = document;
        var s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
    })();
};